import React from "react"

import { Box } from "../helpers/styledComponents"
import BookRow from "../components/layout/bookRow"
import {
  getShelfTitleForMember,
  getFavoriteBooksForMember,
  getMemberName,
} from "../helpers/dataTransformers/member"
import Layout from "../components/layout/base"
import SEO from "../components/seo"

const MemberTemplate = ({ data }) => {
  const { sanityMember } = data
  const books = getFavoriteBooksForMember(sanityMember)
  const title = getMemberName(sanityMember).toLowerCase()

  return (
    <Layout>
      <SEO title={title} />
      <Box width="100%" display="flex" justifyContent="center" p={[4, 5]}>
        <BookRow
          books={books}
          noPaddingTop={true}
          title={getShelfTitleForMember(sanityMember)}
        />
      </Box>
    </Layout>
  )
}

export const pageQuery = graphql`
  query memberTemplateQuery($id: String!) {
    sanityMember(id: { eq: $id }) {
      details {
        name
      }
      favoriteBooks {
        bookCover {
          asset {
            fluid(maxWidth: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`

export default MemberTemplate
